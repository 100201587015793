var b3__sidebar = {
	Theme: "light",
	IsTouchScreen: false,
	TouchScreenMaxSize: 1023,
	Init: function() {
		var sidebar = $(".b3__sidenav");
		if(sidebar.length == 0) return;

		this.Theme = this.GetTheme();

		this.CheckWindowSize();
		this.ResizeHelper(this.CheckWindowSize,[]);
		this.ToggleStateBinding(sidebar);
	},
	GetTheme: function() {
		var bodyClasses = document.querySelector("body").classList,
			theme = "light";
		
		if(bodyClasses.contains("b3__theme--dark"))
			theme = "dark";
		else if(bodyClasses.contains("b3__theme--trader"))
			theme = "trader";

		return theme;
	},
	ResizeHelper: function(fn,args) {
		var _self = this,
			rtime,
			timeout = false,
			delta = 200,
			resizeend = function() {
				if (new Date() - rtime < delta) {
					setTimeout(resizeend, delta);
				} else {
					timeout = false;
					fn.apply(_self,args);
				}               
			};

		$(window).resize(function() {
			rtime = new Date();
			if (timeout === false) {
				timeout = true;
				setTimeout(resizeend, delta);
			}
		});
	},
	CheckWindowSize: function() {
		this.IsTouchScreen = (window.innerWidth < this.TouchScreenMaxSize);
		this.ApplyHeaderSiteBrandColor();
	},
	ApplyHeaderSiteBrandColor: function() {
		var _self = this,
			topBarBrand = $(".b3__sidenav__header-brand"),	
			topBarSite = $(".b3__sidenav__header-site"),	
			menu = $(".b3__sidenav__menu"),
			bottomMenu = $(".b3__sidenav__bottom-menu"),
			lightTheme = "b3__theme--light",
			darkTheme = "b3__theme--dark",
			traderTheme = "b3__theme--dark";

		topBarBrand[0].classList.remove(lightTheme,darkTheme,traderTheme);
		topBarSite[0].classList.remove(lightTheme,darkTheme,traderTheme);
		menu[0].classList.remove(lightTheme,darkTheme,traderTheme);
		bottomMenu[0].classList.remove(lightTheme,darkTheme,traderTheme);
		
		if(_self.Theme == "light") {
			topBarBrand[0].classList.add(darkTheme);
			menu[0].classList.add(darkTheme);
			bottomMenu[0].classList.add(darkTheme);
			
			if(_self.IsTouchScreen) 
				topBarSite[0].classList.add(darkTheme);


		} else if(_self.Theme == "dark") {
			topBarBrand[0].classList.add(darkTheme);

			if(_self.IsTouchScreen) {
				menu[0].classList.add(lightTheme);
				topBarSite[0].classList.add(lightTheme);
			}
		} 
	},
	ToggleStateBinding: function(sidebar) {
		var _self = this,
			toggleButton = $(".b3__sidenav__toggle",sidebar);

		toggleButton.off("click.b3_sidenav").on("click.b3_sidenav",function(e) {
			e.preventDefault();

			var toggleClassname = _self.IsTouchScreen ? "b3__sidenav--opened" : "b3__sidenav--minimized";
			sidebar[0].classList.toggle(toggleClassname);
		});
	}

};