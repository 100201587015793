var b3___organism = {
	Init: function() {
			this.Organism();
	},
	Organism: function() {
		var year = $(".b3__footter-year");
		
		if(year.length) {
			var d = new Date();
			year.html(d.getFullYear());
		}
	}
};