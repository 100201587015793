//=require _components/_form-entry.js
//=require _components/_organism.js
//=require _components/_nav-fixes.js
//=require _components/_chart.js
//=require _components/_datepicker.js
//=require _components/_loading.js
//=require _components/_datagrid.js
//=require _components/_sidebar.js

$(function() {
    b3___organism.Init();
    b3__datagrid.Init();
    b3__datepicker.Init();
    b3__chart.Init();
    b3__formControl.Init();
    b3__navControl.Init();
    b3__sidebar.Init();
});