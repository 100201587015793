var b3__formControl = {
    ListenerNamespace: "b3ds",
    ListenerValidate: "b3ds-validate",
    Init: function() {
        var _self = this,
            fields = _self.SelectFields(),
            frm = _self.SelectNeedsValidation();

        fields.each(function() {
            _self.CheckIfHasLabel(this);
            _self.SetFieldWithValue(this);
            _self.CheckIfMultiple(this);
            _self.CheckIfPlaceholder(this);
            _self.CheckIfDisabled(this);
            _self.CheckIfSearch(this);
            _self.CheckIfDatepicker(this);
            _self.CheckSize(this);
            _self.ObserveFieldDisability(this);
            _self.ApplyMask(this);
        });

        _self.ApplyChangeBehavior(fields);
        _self.ApplyFocusBlurBehavior(fields);
        //_self.ApplyKeyupBehavior(fields);
        _self.ApplySubmitBehavior(frm);
    },
    SelectFields: function() {
        return $(".b3__form-control > input:not(:hidden), .b3__form-control > textarea, .b3__form-select > select, .b3__form-file > input:not(:hidden)");
    },
    SelectNeedsValidation: function() {
        return $(".needs-validation, .b3__form-needs-validation");
    },
    ApplyChangeBehavior: function(field) {
        var _self = this;

        if(field[0] && field[0].parentNode.classList.contains("b3__form-file"))
            _self.RecordOriginalLabel(field[0]);

        field.off("change."+_self.ListenerNamespace).on("change."+_self.ListenerNamespace,function() {
            _self.SetFieldWithValue(this);
            _self.CheckIfDisabled(this);
            
            if(this.parentNode.classList.contains("b3__form-file")) {
                _self.SetFilenameToLabel(this);
            }
        });
    },
    ApplyFocusBlurBehavior: function(field) {
        var _self = this;

        field.off("focus."+_self.ListenerNamespace).on("focus."+_self.ListenerNamespace,function() {
            _self.SetFieldFocused(this,true);
        }).off("blur."+_self.ListenerNamespace).on("blur."+_self.ListenerNamespace,function() {
            _self.SetFieldFocused(this,false);
        });
    },
    ApplyKeyupBehavior: function(field) {
        var _self = this;

        field.off("blur."+_self.ListenerNamespace).on("blur."+_self.ListenerNamespace,function() {
            _self.SetValidInvalid(this);
        });
    },
    ApplySubmitBehavior: function(frm) {
        var _self = this;

        frm.off("submit."+_self.ListenerNamespace).on("submit."+_self.ListenerNamespace,function(e) {
            if (this.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }

            var fields = this.querySelectorAll('input:required:not([hidden]), select:required:not([hidden]), textarea:required:not([hidden])');
            fields.forEach(function(field) {
                _self.SetValidInvalid(field);
            });
            
            this.classList.add('was-validated');
        });
    },
    ApplyMask: function(field) {
        var _self = this,
            rtn = false,
            input = $(field),
            maskDefaults = {
                watchInterval: 10,
                clearIfNotMatch: true,
                dataMask: false,
                translation:  {
                    'a': {pattern: /[a-zA-Z]/},
                    '*': {pattern: /[a-zA-Z0-9]/},
                    '?': {pattern: /\d/, optional: true},
                    '9': {pattern: /\d/, optional: false},
                }
            };
        
        if(input.data("b3Mask") != undefined){
            var b3mask = input.data("b3Mask"),
                b3maskReverse = input.data("b3MaskReverse") != undefined && input.data("b3MaskReverse") == true ? true : false,
                maskToApply = _self.SetMaskValue(b3mask, input);

            if(maskToApply != null) 
                input.unmask().mask(maskToApply, !b3maskReverse ? maskDefaults : {reverse: true}).off("blur."+_self.ListenerValidate);

            input.off("change."+_self.ListenerValidate).on("change."+_self.ListenerValidate,function(){
                _self.SetMaskValidate(field, b3mask);
            });

            rtn = true;
        }
            
        return rtn;
    },
    SetMaskValue: function(mask) {
        var b3mask = "";

        switch(mask) {
            case "date":
                b3mask = "99/99/9999";
                break;
            case "time":
                b3mask = "00:00";
                break;
            case "phone":
                b3mask = "(99) 9999.9999?9";
                break;
            case "cpf":
                b3mask = "999.999.999-99";
                break;
            case "cnpj":
                b3mask = "99.999.999/9999-99";
                break;
            case "cep":
                b3mask = "99999-999";
                break;
            case "money":
                b3mask = "#.##0,00";
                break;
            case "email":
                b3mask = null;
                break;
            default:
                b3mask = mask;
                break;
        }

        return b3mask;
    },
    SetMaskValidate: function(field, mask) {
        var _self = this,
            status = false;

        switch(mask) {
            case "date":
                status = _self.ValidateDate(field.value);
                break;
            case "email":
                status = _self.ValidateMail(field.value);
                break;
            case "phone":
                status = _self.ValidatePhone(field.value);
                break;
            case "cpf":
                status = _self.ValidateCPF(field.value);
                break;
            case "cnpj":
                status = _self.ValidateCNPJ(field.value);
                break;
            case "time":
                status = _self.ValidateTime(field.value);
                break;
            default:
                status = true;
                break;
        }

        field.setAttribute("data-valid",status);
        _self.SetValidInvalid(field);
    },
    SetFieldWithValue: function(obj) {
        obj.parentNode.classList.toggle("has-value",obj.value != '');
    },
    CheckIfMultiple: function(obj) {
        obj.parentNode.classList.toggle("is-multiple",obj.multiple);
    },
    CheckIfPlaceholder: function(obj) {
        obj.parentNode.classList.toggle("has-placeholder",obj.placeholder && obj.placeholder != '')
    },
    CheckIfHasLabel: function(obj) {
        obj.parentNode.classList.toggle("not-label",obj.parentNode.querySelectorAll("label").length == 0);
    },
    CheckIfDisabled: function(obj) {
        obj.parentNode.classList.toggle("is-disabled",obj.disabled);
    },
    CheckIfSearch: function(obj) {
        obj.parentNode.classList.toggle("is-search",obj.type == 'search');
        obj.parentNode.parentNode.classList.toggle("is-search",obj.parentNode.parentNode.classList.contains("input-group"));
    },
    CheckIfDatepicker: function(obj) {
        obj.parentNode.classList.toggle("is-datepicker",obj.classList.contains("b3__form-datepicker"));
    },
    CheckSize: function(obj) {
        obj.parentNode.classList.toggle("large",obj.parentNode.classList.contains("large") || obj.classList.contains('form-control-lg'));
        obj.parentNode.classList.toggle("small",obj.parentNode.classList.contains("small") || obj.classList.contains('form-control-sm'));
    },
    SetFieldFocused: function(obj,focused) {
        obj.parentNode.classList.toggle("is-focused",focused);
    },
    SetValidInvalid: function(obj) {
        var dataValidate = typeof obj.dataset.valid != "undefined" ? true : false,
            parseValidate =  !dataValidate ? false : JSON.parse(obj.dataset.valid);

        obj.parentNode.classList.toggle("is-valid", dataValidate ? parseValidate : obj.validity.valid);
        obj.parentNode.classList.toggle("is-invalid", dataValidate ? !parseValidate : !obj.validity.valid);
    },
    RecordOriginalLabel: function(obj) {
        var label = $(obj).parent().find("label");
        label.data("label",label.text());
    },
    SetFilenameToLabel: function(obj) {
        var filename = obj.value,
            label = $(obj).parent().find("label");

        filename = filename.replace(/\\/g, '/').replace(/.*\//, '');

        if(filename == '') filename = label.data("label");
        label.html(filename);
    },
    ObserveFieldDisability: function(obj) {
        var observer = new MutationObserver(function(mutations) {
                for (var i=0, mutation; mutation = mutations[i]; i++) {
                    obj.parentNode.classList.toggle("is-disabled",mutation.attributeName == 'disabled' && mutation.target.disabled);
                }
            });
        
        observer.observe(obj, {attributes: true});
    },
    ValidateCPF: function(cpf) {
        var cpf = cpf.replace(/[^\d]+/g,'');

		var Soma;
		var Resto;
		Soma = 0;
		
		if (
				cpf == "00000000000" ||
				cpf == "11111111111" ||
				cpf == "22222222222" ||
				cpf == "33333333333" ||
				cpf == "44444444444" ||
				cpf == "55555555555" ||
				cpf == "66666666666" ||
				cpf == "77777777777" ||
				cpf == "88888888888" ||
				cpf == "99999999999"
			) return false;
	     
		for (i=1; i<=9; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
		Resto = (Soma * 10) % 11;
	     
		if ((Resto == 10) || (Resto == 11))  Resto = 0;
		if (Resto != parseInt(cpf.substring(9, 10)) ) return false;
	     
		Soma = 0;
		for (i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
		Resto = (Soma * 10) % 11;
	     
		if ((Resto == 10) || (Resto == 11))  Resto = 0;
		if (Resto != parseInt(cpf.substring(10, 11) ) ) return false;

		return true;
    },
	ValidateCNPJ: function(cnpj) {
		cnpj = cnpj.replace(/[^\d]+/g,'');
 
		if (cnpj == '') return false;
		if (cnpj.length != 14) return false;
 
		// Elimina CNPJs invalidos conhecidos
		if (cnpj == "00000000000000" || 
			cnpj == "11111111111111" || 
			cnpj == "22222222222222" || 
			cnpj == "33333333333333" || 
			cnpj == "44444444444444" || 
			cnpj == "55555555555555" || 
			cnpj == "66666666666666" || 
			cnpj == "77777777777777" || 
			cnpj == "88888888888888" || 
			cnpj == "99999999999999")
		return false;

		tamanho = cnpj.length - 2
		numeros = cnpj.substring(0,tamanho);
		digitos = cnpj.substring(tamanho);
		soma = 0;
		pos = tamanho - 7;

		for (i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		if (resultado != digitos.charAt(0)) return false;
         
		tamanho = tamanho + 1;
		numeros = cnpj.substring(0,tamanho);
		soma = 0;
		pos = tamanho - 7;
		for (i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2) pos = 9;
		}
		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
		
		if (resultado != digitos.charAt(1)) return false;
           
		return true;
    },
    ValidatePhone: function(num) {
        var rtn = true;
        num = num.replace(/\(/g,"").replace(/\)/g,"").replace(/ /g,"").replace(/\./g,"");

        if(num !== "") {
            if(
                num.indexOf("0000000") > -1 ||
                num.indexOf("1111111") > -1 ||
                num.indexOf("2222222") > -1 ||
                num.indexOf("3333333") > -1 ||
                num.indexOf("4444444") > -1 ||
                num.indexOf("5555555") > -1 ||
                num.indexOf("6666666") > -1 ||
                num.indexOf("7777777") > -1 ||
                num.indexOf("8888888") > -1 ||
                num.indexOf("9999999") > -1
                ) {
                rtn = false;	
            } 
        }

        return rtn;
    },
	ValidateMail: function(email) { 
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
    },
    ValidateDate: function(date) {
        var v = date,
            status = true;

        v = v.split("/");
        
        var vDate = new Date(v[2],(parseInt(v[1])-1),v[0]);

        if(v !== "" &&  (parseInt(v[0]) !== parseInt(vDate.getDate()) || parseInt(v[1]) !== (parseInt(vDate.getMonth())+1))) {
            status = false;
        }

        return status;
    },
    ValidateTime: function(time) {
        var status = true;

        time = time.split(":");

        if(parseInt(time[0]) > 23 || parseInt(time[1]) > 60)
            status = false;

        return status;
    }
};